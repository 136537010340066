<template>
  <protect-register :typePage="typePage"></protect-register>
</template>

<script>
import ProtectRegister from "@/views/modules/collection/business/protect/protectRegister.vue";

export default {
      name: "protectAuditing",
    components: {ProtectRegister},
    data(){
        return{
            typePage:'protectAuditing',
        }
    },
}
</script>

<style scoped>

</style>